import React from "react";

function Loader(props) {
  return (
    <div
      id="loader"
      className="d-flex align-items-center justify-content-center"
      style={{ width: "100%", height: "100vh" }}
    >
      <img
        src="assets/images/logo-gif.gif"
        alt="carthi"
      />
    </div>
  );
}

export default Loader;
