import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loader from "../../Utils/Loader";
import Search from "../../Search";
import CatlogueHeader from "../CatlogueHeader";
import  Commonsharelist from "./Commonsharelist";
function Catlogueshare(props) {
  const [catloguelist, setCatloguelist] = useState([]);
  const [DealerData, setDealerData] = useState({ Name: "", Mobileno: "" });
  const [originalList, setOriginalList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  let params = useParams();
  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/BrandingProfile/GetBrandingProfile?Rand=" +
        params.id
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success === true) {
          setDealerData(res.Data);
        }
      });

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/CarCateloge/AllCatelogebyRandnoDealerid?carstatus=Catlogue&dealerrandno=" +
        params.id
    )
      .then((response) => response.json())
      .then((res) => {
        setCatloguelist(res.Data || []);
        setOriginalList(res.Data || []);
        setLoading(false);
      });
  }, [navigate, params.id]);

  const handleCallback = (childData) => {
    setLoading(true);
    setCatloguelist(childData);
    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div style={{ marginTop: "-66px" }}>
      <CatlogueHeader Datafromparent={DealerData} />

      <div className="page-space">
        <h3 className="text-center" style={{ color: "#ff002a" }}>
          Total Cars : {originalList.length}
        </h3>
        <Search dataFromParent={originalList} parentCallback={handleCallback} />
        <Commonsharelist catloguelist={catloguelist} DealerData={DealerData} Branding={"W_hB"}/>
        
        
        
        
        {/* {catloguelist.length === 0 ? (
         <NoCars/>
        ) : (
          catloguelist.map((list) => (
            <div className="car-div" key={list.carid}>
              <div>
                <div className="car-radius position-relative">
                  <img
                    src="assets/images/abslogo.png"
                    className="abs-logo"
                    alt=""
                  />
                  <Link
                    to={
                      "/CatDetails/" +
                      list.Rand +
                      "/" +
                      DealerData.DealerRand +
                      "/W_hB"
                    }
                  >
                    <img src={list.carphoto} className="w-100" alt="" />
                  </Link>
                  <div className="car-smdetails">
                    <span className="abs-span">{list.fueltypeid}</span>

                    <RemainingPageTitle dataFromParent={list} />
                    <div className="car-km justify-content-between">
                      <div>
                        <img
                          src="assets/images/car-km.svg"
                          alt=""
                          className="ms-2"
                        />
                        <span>{list.km} km</span>
                      </div>
                      <div className="d-inline-flex align-items-center">
                        <Link
                          to={
                            "/CatDetails/" +
                            list.Rand +
                            "/" +
                            DealerData.DealerRand +
                            "/W_hB"
                          }
                        >
                          <img src="assets/images/view-more.png" alt="" />
                        </Link>
                        <a href={"tel:" + DealerData.Mobileno} className="ms-2">
                          <img src="assets/images/call-now.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )} */}
      </div>
    </div>
  );
}

export default Catlogueshare;
