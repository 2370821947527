import React from 'react';
import Header from '../Header';
import Stocklist from './Stocklist';

function Report(props) {
    return (
        <div>
          <Header/>
          <div className="content">
        <div className="text-center">
          <h2 className="page-title mb-4">Reports</h2>
        </div>
          <Stocklist/>  
          </div>
        </div>
    );
}

export default Report;