import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "../Header";
import Loader from "../Utils/Loader";
import { Userlogin,GetDealerdata,Toast } from "../Utils/general-function";
import html2canvas from "html2canvas";

function AllImages(props) {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedcategory, setSelectedcategory] = useState(0);
  const [Display, setDisplay] = useState("inline-block");
  const navigate = useNavigate();
  const countRef = useRef(1);
  const mount = useRef(true);
  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    },
  };

  useEffect(() => {
    if (!Userlogin()) {
      navigate("/Login");
      return;
    }

    const fetchdatasync = async () => {
      // const Dealerdata = await GetDealerdata();
      // if ((Dealerdata.nCity === "")) {
      //   Toast(false, "", "Please Complete your Profile");
      //   navigate("/Profile");
      //   return;
      // }       
      if (mount.current) {
         fetchAllImages(0);
         mount.current = false;  
         fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/categorydata")
        .then((response) => response.json())
        .then((res) => {
          console.log(res,"other");
          
          setCategories(res.Data);
        })
        .catch((error) => console.error("Error fetching categories:", error));
      }
    
    };
    fetchdatasync();
  }, []);

  const fetchAllImages = async (category) => {
    setLoading(true);
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/UploadImages/AllUploadContentImages?pageindex=${countRef.current}&categoryid=${category}`,
        header
      );
      const res = await response.json();
      console.log(res);
      setDisplay("inline-block");
      if (res.Success) {
        countRef.current === 1?setImages(res.Data):setImages((prevImages) => [...prevImages, ...res.Data]);
        countRef.current += 1; // Increment index after fetching
      }
       if(!res.Data || res.Data.length<6){
        setDisplay("none")
       }
    } catch (error) {
      console.error("Exception found:", error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e) => {
    setSelectedcategory(e.target.value);
    countRef.current=1;
    fetchAllImages(e.target.value)
  };

  const DownloadImg = async (randomid, name) => {
    setLoading(true);
    await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/FrameMaster/DownloadFrame?imagerandomid=" +
        randomid,
      header
    )
      .then((response) => response.json())
      .then(async (res) => {
        const input = document.getElementById("pic");
        input.innerHTML = await res.Data.FrameData;
        input.style.display = "block";

        html2canvas(input, {
          useCORS: true,
        })
          .then(async (canvas) => {
            const imgData = await canvas.toDataURL("image/jpeg");

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = imgData;
            a.download = name;
            a.click();

            input.style.display = "none";
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      });
  };

  return (
    <div>
      {console.log(countRef.current)}
      <Header />
      <Link
        to="/allframe"
        className="sharebtn sharbtnpos"
        style={{ padding: "8px" }}
      >
        Change Frame
      </Link>
      {loading && <Loader />}
      <div className="page-space">
        <div className="form-div">
          <select
            className="form-select form-control"
            name="selectedcategory"
            // value={selectedcategory}
            onChange={onChange}
            defaultValue=""
          >
            <option value="" disabled={true}>
              Choose a Category
            </option>
            <option value="0">All</option>
            {categories.map((list, i) => (
              <option value={list.Value} key={i}>
                {list.Text}
              </option>
            ))}
          </select>
        </div>
        {images.length === 0 ? (
          <div className="text-center">
            <h4
              style={{
                margin: "20px auto",
                fontSize: "20px",
                color: "#ff1237",
              }}
            >
              No Record Found
            </h4>
          </div>
        ) : (
          <>
            <div className="car-grid">
              {images.map((data) => (
                <div className="grt-imgdiv" key={data.random}>
                  <div className="grt-reldiv">
                    <img
                      src={data.imageupload}
                      alt="Carthi"
                      className="img-fluid"
                      style={{ borderRadius: "4px" }}
                    />
                    <div className="get-absdiv">
                      <h6 className="mt-2">{data.title}</h6>
                    </div>
                  </div>
                  <button
                    className="submitbtn"
                    onClick={(e) =>
                      DownloadImg(data.random, data.imageuploadname)
                    }
                  >
                    Download
                  </button>
                </div>
              ))}
            </div>
            <div className="text-center pt-3 mb-3">
              <button
                type="submit"
                className="submitbtn"
                style={{display:Display}}
                onClick={(e) => fetchAllImages(selectedcategory)}>
                Load More
              </button>
            </div>
          </>
        )}
      </div>
      <div id="pic" style={{ width: "1080px" }}></div>
    </div>
  );
}

export default AllImages;
