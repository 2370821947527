import React, { createContext, useState, useContext } from 'react';

const SearchContext = createContext();

const SearchProvider = ({ children }) => {
    const [search, setSearch] = useState({
        state:JSON.parse(localStorage.getItem("state")),
        city:JSON.parse(localStorage.getItem("city")),
        company:"",
        Model:"",
        fuel:"",
        ModelYear:[2003,2024],
        vehicle:""
    });
    const[Specificcat,setSpecificcat]=useState({
        Modelobj:"",
        Branding:"W_hB",
        resultlist:[],
        count:0
    })
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(1);
    return (
        <SearchContext.Provider value={{ search, setSearch, results, setResults,count,setCount,Specificcat,setSpecificcat }}>
            {children}
        </SearchContext.Provider>
    );
};

const useSearch = () => useContext(SearchContext);

export { SearchProvider, useSearch };
