import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Toast,
  Userlogin,
  ownerDrop,
  DateFormate,
  customStyles,
} from "./Utils/general-function";
import Header from "./Header";
import Upload_photo from "./Uploadimage/Upload-Photo";
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
import { parse } from "date-fns";
import { Helmet } from "react-helmet";
import Loader from "./Utils/Loader";
import Select from "react-select";
import styled from "styled-components";

export const Span = styled.span`
  font-size: 13px;
  color: #686868;
  letter-spacing: 0.5px;
`;
const Star = styled.span`
  color: #ff1237;
  margin-left: 2px;
`;
function CarInquiry(props) {
  const [loading, setLoading] = useState(true);
  const [Companylist, setCompanylist] = useState([]);
  const [vehicletypes, setVehicletypes] = useState([]);
  const [Brandlist, setBrandlist] = useState([]);
  const [Varientlist, setVarientlist] = useState([]);
  const [Colorlist, setColorlist] = useState([]);
  const [Fuelist, setFuelist] = useState([]);
  const [Gearlist, setGearlist] = useState([]);
  const [input, setInput] = useState({
    stardate: DateFormate(new Date()),
    enddate: DateFormate(new Date()),
    Charges: "",
    owner: "",
    seatingcapicity: "",
    year: "",
    km: "",
    nInsurancetype: "Comprehensive",
    color: "",
    sunroof: "No",
    cc: "",
    nSaleprice: "",
    nPurchaseprice: "",
    vehicle:""
  });
  const [check, setCheck] = useState("Nill");
  const [carstatus, setCarstatus] = useState("No");
  const [src, setSrc] = useState("");
  const [Features, setFeatures] = useState([]);
  const [SelectedFeatures, setSelectedFeatures] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    let model;
    if (Userlogin()) {
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/companydata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            setCompanylist(res.Data);
          }
        });
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Fueldata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            setFuelist(res.Data);
          }
        });
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Geardata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            setGearlist(res.Data);
          }
        });
        fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Vehiclenamedata")
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          
          if (mounted) {
            setVehicletypes(res.Data);
          }
        });

      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Featuresdata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            var option = [];
            var obj;
            res.Data.map((data) => {
              obj = {
                label: data.Text,
                value: data.Value,
              };
              option.push(obj);
              return option;
            });
            setFeatures(option);
          }
        });
      // async function loadcoco(){
      //   // eslint-disable-next-line no-undef
      //    model=await cocoSsd.load();
      //    console.log("hiii");
      // }
      // loadcoco();
      setLoading(false);
    } else {
      navigate("/Login");
    }
    return () => {
      mounted = false;
      if (model) {
        model.dispose();
      }
    };
  }, [navigate]);

  // async function detectCar() {
  //   const imageInput = document.getElementById("imageInput");
  //   const resultDiv = document.getElementById("result");
  //   // eslint-disable-next-line no-undef
  //   const model = await cocoSsd.load();
  //   console.log(model);
  //   if (!imageInput.files || !imageInput.files[0]) {
  //     resultDiv.innerText = "No image uploaded";
  //     return;
  //   }

  //   const imageFile = imageInput.files[0];
  //   const imageURL = URL.createObjectURL(imageFile);

  //   const image = new Image();
  //   image.src = imageURL;

  //   image.onload = async function () {
  //     try {
  //       // Load the COCO-SSD model

  //       // Make predictions
  //       const predictions = await model.detect(image);
  //       console.log("predictions", predictions);
  //       // Check if a car is detected
  //       const containsCar = predictions.some(
  //         (prediction) => prediction.class === "car"
  //       );

  //       // Display result
  //       if (containsCar) {
  //         resultDiv.innerText = "Car detected";
  //       } else {
  //         resultDiv.innerText = "No car detected";
  //       }
  //     } catch (error) {
  //       console.error("Error detecting car:", error);
  //       resultDiv.innerText = "Error detecting car";
  //     }
  //   };
  // }

  const handleCompanychange = (obj) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Common/activemodel/" +
        obj.Value
    )
      .then((response) => response.json())
      .then((res) => {
        setBrandlist(res.Data || []);
        setVarientlist([]);
        setColorlist([]);
        setInput((prevInput) => ({
          ...prevInput,
          company: obj.Value,
          Model: "",
          color: "",
        }));
      });
  };
  const handleModalchange = (obj) => {
    var details =
      "companyid=" +
      encodeURIComponent(input.company) +
      "&Modelid=" +
      encodeURIComponent(obj.Value);
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: details,
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/api/Common/veriantandcolour",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setVarientlist(res.Data.veriantdata || []);
        setColorlist(res.Data.Colourdata || []);
        setInput((prevInput) => ({
          ...prevInput,
          Model: obj,
          varient: "",
          color: "",
        }));
      });
  };

  const handlevarient = (obj) => {
    setInput((prevInput) => ({
      ...prevInput,
      varient: obj.Value,
      color: "",
    }));
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    
    const isCarNumber = e.target.name === "carnumber";
    // const isVarient = e.target.name === "varient";
    // const isColor = e.target.name === "color";
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: isCarNumber ? value.toUpperCase() : value,
      // color: isVarient ? "" : isColor ? value : prevInput.color,
    }));
  };

  const ondropdown = (opt) => {
    setSelectedFeatures(opt);
  };

  const HandleNumerictextbox = (e) => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    const { name, value } = e.target;
    if (value === "" || pattern.test(value)) {
      setInput((prevInput) => ({ ...prevInput, [name]: value }));
    }
  };
  const onDatechange = (value, name) => {
    setInput((prevInput) => ({
      ...prevInput,
      [name]: DateFormate(value),
    }));
  };

  const oncheck = (e) => {
    const target = e.target;
    setCheck(target.checked ? "Yes" : "Nill");
  };

  const onstatuscheck = (e) => {
    const target = e.target;
    setCarstatus(target.checked ? "Yes" : "No");
  };
  const Validate = () => {
    let errors = {};
    let isValid = true;
    if (!input["carnumber"]) {
      isValid = false;
      errors["carnumber"] = "This feild is required.";
    }
    if (typeof input["carnumber"] !== "undefined") {
      var pattern = new RegExp(process.env.REACT_APP_CARNO_PATTERN);
      if (!pattern.test(input["carnumber"])) {
        isValid = false;
        errors["carnumber"] = "Enter valid vehicle number";
      }
      if (input["carnumber"].length > 11) {
        isValid = false;
        errors["carnumber"] = "Enter valid vehicle number";
      }
    }
    if (!input["company"]) {
      isValid = false;
      errors["company"] = "This feild is required";
    }
    if (!input["Model"]) {
      isValid = false;
      errors["Model"] = "This feild is required";
    }
    if (!input["varient"]) {
      isValid = false;
      errors["varient"] = "This feild is required";
    }
    if (!input["fuel"]) {
      isValid = false;
      errors["fuel"] = "This feild is required";
    }
    if (!input["color"]) {
      isValid = false;
      errors["color"] = "This feild is required";
    }
    if (!input["vehicle"]) {
      isValid = false;
      errors["vehicle"] = "This feild is required";
    }
    if (input["insurance"] === true) {
      if (!input["enddate"]) {
        isValid = false;
        errors["enddate"] = "This feild  is required ";
      }
    }
    if (!input["owner"]) {
      isValid = false;
      errors["owner"] = "This feild is required";
    }
    if (!input["Charges"]) {
      isValid = false;
      errors["Charges"] = "This feild is required";
    }
    if (!input["km"]) {
      isValid = false;
      errors["km"] = "This feild is required";
    }
    if (typeof input["km"] !== undefined) {
      if (input["km"].length > 6) {
        isValid = false;
        errors["km"] = "Enter valid kilometer";
      }
    }
    if (!input["seatingcapicity"]) {
      isValid = false;
      errors["seatingcapicity"] = "This feild is required";
    }
    if (typeof input["seatingcapicity"] !== undefined) {
      if (input["seatingcapicity"].length > 1) {
        isValid = false;
        errors["seatingcapicity"] = "Enter valid capacity";
      }
    }
    if (!input["photo"]) {
      isValid = false;
      errors["photo"] = "Vehicle photo is required";
    }

    if (!input["year"]) {
      isValid = false;
      errors["year"] = "This feild is  required";
    }
    if (typeof input["year"] !== undefined) {
      if (input["year"].length > 4) {
        isValid = false;
        errors["year"] = "Enter valid Year";
      }
    }
    if (!input["cc"]) {
      isValid = false;
      errors["cc"] = "This feild is required";
    }
    if (typeof input["cc"] !== undefined) {
      if (input["cc"].length > 4) {
        isValid = false;
        errors["cc"] = "Enter valid cubic capacity";
      }
    }
    setErrors(errors);

    return isValid;
  };

  const handleSubmit = () => {
    if (Validate()) {
      setLoading(true);
      var param = {
        carnumber: input.carnumber,
        companyid: input.company,
        carmodelid: input.Model.Value,
        variantid: input.varient,
        fueltypeid: input.fuel,
        modelyear: input.year,
        colour: input.color,
        insurance: check,
        insuranceendtdate: check === "Yes" ? input.enddate : "",
        km: input.km,
        owner: input.owner,
        seatingcapicity: input.seatingcapicity,
        Photoname: input.Photoname,
        carphoto: input.photo,
        gear: input.Charges,
        nInsurancetype: check === "Yes" ? input.nInsurancetype : "",
        nSunRoof: input.sunroof,
        nCc: input.cc,
        nSaleprice: input.nSaleprice,
        nPurchaseprice: input.nPurchaseprice,
        nCaravaibility: carstatus,
        vehicleid:input.vehicle,
        nFeatures:
          SelectedFeatures.length !== 0
            ? SelectedFeatures.map((item) => item.value).join(",")
            : "",
      };
      console.log(param);
      var formBody = [];
      for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
        body: formBody,
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/Carmaster/addcar",
        requestOptions
      )
        .then((response) => response.json())
        .then(async (res) => {
          setLoading(false);
          if (res.Success === true) {
            navigate("/MyCar");
            Toast(res.Success, "Vehicle added Successfully", res.Message);
          } else {
            Toast(res.Success, "", res.Message);
          }
        });
    } else {
      console.log("out");
    }
  };
  const handleCallback = (childData) => {
    input["photo"] = childData.url.split(",")[1];
    input["Photoname"] = childData.name;
    setInput(input);
    setSrc(childData.url);
  };
  const selecteddate = (date) => {
    const parsedDate = parse(date, "dd-MM-yyyy", new Date());
    return parsedDate;
  };
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}

      {/* <input type="file" accept="image/*" id="imageInput" />
      <button onClick={(e) => detectCar()}>Detect Car</button>
      <div id="result"></div> */}

      <div className="content">
        <div className="text-center">
          <h2 className="page-title">Add Car</h2>
        </div>

        <div className="form-div">
          <label className="form-label">
            Vehicle Type<Star>*</Star>
          </label>
          <select
            className="form-select form-control"
            name="vehicle"
            value={input.vehicle}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select vehicle Type--
            </option>
            {vehicletypes.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.vehicle}</span>
        </div>
        
        <div className="form-div">
          <label className="form-label">
            Company<Star>*</Star>
          </label>
          {/* <select
            className="form-select form-control"
            name="company"
            defaultValue=""
            onChange={handleCompanychange}
          >
            <option value="" disable="disable">
              --Select Company--
            </option>
            {Companylist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select> */}
          <Select
            placeholder="--Select Company--"
            name="company"
            options={Companylist}
            getOptionLabel={(option) => option.Text}
            getOptionValue={(option) => option.Value}
            onChange={handleCompanychange}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            isSearchable={true}
            styles={customStyles}
          ></Select>

          <span className="text-danger">{errors.company}</span>
        </div>

        <div className="form-div">
          <label className="form-label">
            Model<Star>*</Star>
          </label>
          <Select
            placeholder="--Select Model--"
            value={input.Model}
            name="Model"
            options={Brandlist}
            getOptionLabel={(option) => option.Text}
            getOptionValue={(option) => option.Value}
            onChange={handleModalchange}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            isSearchable={true}
            styles={customStyles}
          ></Select>
          <span className="text-danger">{errors.Model}</span>
        </div>

        <div className="form-div">
          <label className="form-label">
            Varient<Star>*</Star>
          </label>
          <Select
            placeholder="--Select Varient--"
            name="varient"
            options={Varientlist}
            getOptionLabel={(option) => option.Text}
            getOptionValue={(option) => option.Value}
            onChange={handlevarient}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            isSearchable={true}
            styles={customStyles}
          ></Select>
          <span className="text-danger">{errors.varient}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Color<Star>*</Star>
          </label>
          <select
            className="form-select form-control"
            name="color"
            value={input.color}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Color--
            </option>
            {Colorlist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.color}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Fuel<Star>*</Star>
          </label>
          <select
            className="form-select form-control"
            name="fuel"
            defaultValue=""
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Fuel--
            </option>
            {Fuelist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.fuel}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Vehicle number<Star>*</Star> <Span>(Ex. GJ01HQ9999)</Span>
          </label>
          <input
            type="text"
            className="form-control"
            name="carnumber"
            value={input.carnumber}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.carnumber}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Year<Star>*</Star>
          </label>
          <input
            type="text"
            className="form-control"
            name="year"
            value={input.year}
            maxLength="4"
            onChange={HandleNumerictextbox}
          />
          <span className="text-danger">{errors.year}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Kilometer<Star>*</Star>
          </label>
          <input
            type="text"
            className="form-control"
            name="km"
            maxLength="6"
            value={input.km}
            onChange={HandleNumerictextbox}
          />
          <span className="text-danger">{errors.km}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Vehicle owner<Star>*</Star>
          </label>
          <select
            className="form-select form-control"
            name="owner"
            defaultValue=""
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Owner--
            </option>
            {ownerDrop.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.owner}</span>
        </div>

        <div className="form-div">
          <label className="form-label">
            Seating capacity<Star>*</Star>
          </label>
          <input
            type="text"
            className="form-control"
            name="seatingcapicity"
            maxLength="1"
            value={input.seatingcapicity}
            onChange={HandleNumerictextbox}
          />
          <span className="text-danger">{errors.seatingcapicity}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Gear<Star>*</Star>
          </label>
          <select
            className="form-select form-control"
            name="Charges"
            defaultValue=""
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Gear--
            </option>
            {Gearlist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.Charges}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Sunroof<Star>*</Star>
          </label>
          <select
            className="form-select form-control"
            name="sunroof"
            defaultValue="No"
            onChange={handleChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="form-div">
          <label className="form-label">
            Choose a vehicle feature <Span>(optional)</Span>
          </label>
          <Select
            isMulti
            name="feature"
            options={Features}
            onChange={ondropdown}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            isSearchable={false}
            styles={customStyles}
          ></Select>
        </div>

        <div className="form-div">
          <label className="form-label">
            Cubic capacity<Star>*</Star> <Span>(Ex. 1.6)</Span>
          </label>
          <input
            type="number"
            step="0.01"
            className="form-control"
            name="cc"
            value={input.cc}
            maxLength="4"
            onChange={handleChange}
          />
          <span className="text-danger">{errors.cc}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Purchase Price <Span>(optional)</Span>
          </label>
          <input
            type="text"
            className="form-control"
            name="nPurchaseprice"
            value={input.nPurchaseprice}
            maxLength="9"
            onChange={HandleNumerictextbox}
          />
          <span className="text-danger">{errors.nPurchaseprice}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Sale Price <Span>(optional)</Span>
          </label>
          <input
            type="text"
            className="form-control"
            name="nSaleprice"
            value={input.nSaleprice}
            maxLength="9"
            onChange={HandleNumerictextbox}
          />
          <span className="text-danger">{errors.nSaleprice}</span>
        </div>
        <div className="form-check-inline form-div">
          <input
            type="checkbox"
            className="form-check-input"
            id="check2"
            name="carstatus"
            onChange={onstatuscheck}
          />
          <label className="form-label ms-1" htmlFor="check2">
            Coming soon
            <div>
              <Span> (Check to create status- as 'Coming Soon')</Span>
            </div>
          </label>
        </div>
        <div>
          <div className="form-check-inline form-div">
            <input
              type="checkbox"
              className="form-check-input"
              id="check1"
              name="insurance"
              onChange={oncheck}
            />
            <label className="form-label ms-1" htmlFor="check1">
              Insurance
            </label>
          </div>
          {check === "Yes" ? (
            <div>
              <div className="text-center">
                <div className="form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio1"
                    name="nInsurancetype"
                    value="Comprehensive"
                    defaultChecked={
                      input.nInsurancetype === "Comprehensive" ? true : false
                    }
                    onChange={handleChange}
                  />
                  <label className="form-check-label ps-1" htmlFor="radio1">
                    Comprehensive
                  </label>
                </div>
                <div className="form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    name="nInsurancetype"
                    value="Third Party"
                    defaultChecked={
                      input.nInsurancetype === "Third Party" ? true : false
                    }
                    onChange={handleChange}
                  />
                  <label className="form-check-label ps-1" htmlFor="radio2">
                    Third Party
                  </label>
                </div>
                <div className="form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio3"
                    name="nInsurancetype"
                    value="Zero depth"
                    defaultChecked={
                      input.nInsurancetype === "Zero depth" ? true : false
                    }
                    onChange={handleChange}
                  />
                  <label className="form-check-label ps-1" htmlFor="radio3">
                    Zero depth
                  </label>
                </div>
              </div>
              {/* <div className="form-div">
                <label className="form-label">Insurance Start Date</label>

                <DatePicker
                  defaultValue={new Date()}
                  valueFormat={{ dateStyle: "medium" }}
                  name="stardate"
                  onChange={(e) => onDatechange(e, "stardate")}
                  onKeyDown={(e) => e.preventDefault()}
                />

                <span className="text-danger">{errors.stardate}</span>
              </div> */}
              <div className="form-div">
                <label className="form-label">Insurance End Date</label>
                <DatePicker
                  defaultValue={new Date()}
                  valueFormat={{ dateStyle: "medium" }}
                  name="enddate"
                  onChange={(e) => onDatechange(e, "enddate")}
                  onKeyDown={(e) => e.preventDefault()}
                />
                <span className="text-danger">{errors.enddate}</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-div">
          <div className="mb-2 mx-1">
            <span style={{ fontWeight: "500", color: "#ff1237" }}>Note :</span>
            <span className="title" style={{ color: "#ff1237" }}>
              &nbsp; Only one front photo of vehicle can be added from here. HD and
              .HEIC photos are not allowed.
            </span>
          </div>
          <Upload_photo parentCallback={handleCallback} />

          <span className="text-danger">{errors.photo}</span>
          <div className="col-12 text-center pt-2">
            <img src={src} style={{ maxWidth: "200px" }} alt="" />
          </div>
        </div>

        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default CarInquiry;
