import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Toast,
  Userlogin,
  ownerDrop,
  DateFormate,
  customStyles,
} from "./Utils/general-function";
import Header from "./Header";
import Upload_photo from "./Uploadimage/Upload-Photo";
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
import { parse } from "date-fns";
import Loader from "./Utils/Loader";
import Select from "react-select";
import styled from "styled-components";
import {Span} from "./carInquiry"

function Editinquiry(props) {
  const location = useLocation();
  const random = location.state.random;
  const [Companylist, setCompanylist] = useState([]);
  const [Brandlist, setBrandlist] = useState([]);
  const [vehicletypes, setVehicletypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Varientlist, setVarientlist] = useState([]);
  const [Colorlist, setColorlist] = useState([]);
  const [Fuelist, setFuelist] = useState([]);
  const [Gearlist, setGearlist] = useState([]);
  const [input, setInput] = useState({
    company: "",
    Model: "",
    year: "",
    varient: "",
    fuel: "",
    color: "",
    check: "",
    km: "",
    owner: "",
    Charges: 0,
    seatingcapicity: "",
    carnumber: "",
    stardate: new Date(),
    enddate: new Date(),
    Photoname: "",
    Cateloge: "No",
    nInsurancetype: "Comprehensive",
    sunroof: "No",
    cc: "",
    nSaleprice: "",
    nPurchaseprice: "",
    vehicle:""
  });
  const [check, setCheck] = useState("Nill");
  const [carstatus, setCarstatus] = useState("No");
  const [src, setSrc] = useState("");
  const [Features, setFeatures] = useState([]);
  const [SelectedFeatures, setSelectedFeatures] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  
  useEffect(() => {
    let mounted = true;
    if (Userlogin()) {
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/companydata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            setCompanylist(res.Data);
          }
        });
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Fueldata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            setFuelist(res.Data);
          }
        });
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Geardata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            setGearlist(res.Data);
          }
        });
        fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Vehiclenamedata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            setVehicletypes(res.Data);
          }
        });
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Featuresdata")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            var option = [];
            var obj;
            res.Data.map((data) => {
              obj = {
                label: data.Text,
                value: data.Value,
              };
              option.push(obj);
              return option;
            });
            setFeatures(option);
          }
        });

      const header = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/Carmaster/Editcar?Rand=" +
          random,
        header
      )
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          var input = {};
          input.company = res.Data.companyid;
          input.Model = res.Data.carmodelid;
          input.varient = res.Data.variantid;
          input.fuel = res.Data.fueltypeid;
          input.color = res.Data.colour;
          input.year = res.Data.modelyear;
          input.km = res.Data.km;
          input.owner = res.Data.owner;
          input.seatingcapicity = res.Data.seatingcapicity;
          input.Charges = res.Data.gear;
          if (res.Data.insurance === "Yes") {
            input.enddate = new Date(res.Data.insuranceendtdate);
          }
          input.carnumber = res.Data.carnumber;
          input.Photoname = res.Data.Photoname;
          input.Cateloge = res.Data.Cateloge;
          input.nInsurancetype =
            res.Data.insurance === "Yes"
              ? res.Data.nInsurancetype
              : "Comprehensive";
          input.sunroof = res.Data.nSunRoof;
          input.cc = res.Data.nCc;
          input.vehicle=res.Data.vehicleid;
          input.nSaleprice = res.Data.nSaleprice;
          input.nPurchaseprice = res.Data.nPurchaseprice
            ? res.Data.nPurchaseprice
            : "";
          setCarstatus(res.Data.nCaravaibility);
          setSelectedFeatures(JSON.parse(res.Data.Listidjson));
          setInput(input);
          setCheck(res.Data.insurance);
          getModalDropdown(res.Data.companyid);
          getVarientColorDropdown(res.Data.companyid, res.Data.carmodelid);
          setLoading(false);
        });
    } else {
      navigate("/Login");
    }
    return () => {
      mounted = false;
    };
  }, [navigate, random]);

  const handleCompanychange = (e) => {
    getModalDropdown(e.target.value);
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
      Model: "",
      color: "",
    }));
  };
  
  const handleModalchange = (e) => {
    getVarientColorDropdown(input.company, e.target.value);
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
      varient: "",
      color: "",
    }));
  };

  const handleChange = (e) => {
    const isCarNumber = e.target.name === "carnumber";
    const isVarient = e.target.name === "varient";
    const isColor = e.target.name === "color";
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: isCarNumber ? value.toUpperCase() : value,
      color: isVarient ? "" : isColor ? value : prevInput.color,
    }));
  };
  const HandleNumerictextbox = (e) => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    const { name, value } = e.target;
    if (value === "" || pattern.test(value)) {
      setInput((prevInput) => ({ ...prevInput, [name]: value }));
    }
  };
  const onDatechange = (value, name) => {
    setInput((prevInput) => ({
      ...prevInput,
      [name]: DateFormate(value),
    }));
  };

  const oncheck = (e) => {
    const target = e.target;
    setCheck(target.checked ? "Yes" : "Nill");
  };
  const Validate = () => {
    let errors = {};
    let isValid = true;

    if (!input["company"]) {
      isValid = false;
      errors["company"] = "Please select company";
    }
    if (!input["Model"]) {
      isValid = false;
      errors["Model"] = "Please select model";
    }
    if (!input["varient"]) {
      isValid = false;
      errors["varient"] = "Please select varient";
    }
    if (!input["fuel"]) {
      isValid = false;
      errors["fuel"] = "Please select fuel";
    }
    if (!input["color"]) {
      isValid = false;
      errors["color"] = "Please select color";
    }
    if (!input["vehicle"]) {
      isValid = false;
      errors["vehicle"] = "This feild is required";
    }
    if (input["insurance"] === true) {
      if (!input["enddate"]) {
        isValid = false;
        errors["enddate"] = "This feild  is required ";
      }
    }
    if (!input["owner"]) {
      isValid = false;
      errors["owner"] = "This feild is is required";
    }
    if (!input["Charges"]) {
      isValid = false;
      errors["Charges"] = "This feild is required";
    }
    if (!input["km"]) {
      isValid = false;
      errors["km"] = "This feild is required";
    }
    if (typeof input["km"] !== undefined) {
      if (input["km"].length > 6) {
        isValid = false;
        errors["km"] = "Enter valid kilometer";
      }
    }
    if (!input["seatingcapicity"]) {
      isValid = false;
      errors["seatingcapicity"] = "This feild is required";
    }
    if (typeof input["seatingcapicity"] !== undefined) {
      if (input["seatingcapicity"].length > 1) {
        isValid = false;
        errors["seatingcapicity"] = "Enter valid capacity";
      }
    }
    if (!input["year"]) {
      isValid = false;
      errors["year"] = "This feild is  required";
    }
    if (typeof input["year"] !== undefined) {
      if (input["year"].length > 4) {
        isValid = false;
        errors["year"] = "Enter valid Year";
      }
    }
    if (!input["cc"]) {
      isValid = false;
      errors["cc"] = "This feild is required";
    }
    if (typeof input["cc"] !== undefined) {
      if (input["cc"].length > 4) {
        isValid = false;
        errors["cc"] = "Enter valid cubic capacity";
      }
    }
    setErrors(errors);

    return isValid;
  };
  const ondropdown = (opt) => {
    setSelectedFeatures(opt);
  };
  const handleSubmit = () => {
    if (Validate()) {
      var param = {
        Rand: random,
        carnumber: input.carnumber,
        companyid: input.company,
        carmodelid: input.Model,
        variantid: input.varient,
        fueltypeid: input.fuel,
        modelyear: input.year,
        colour: input.color,
        insurance: check,
        insuranceendtdate: check === "Yes" ? input.enddate : "",
        km: input.km,
        owner: input.owner,
        seatingcapicity: input.seatingcapicity,
        Photoname: input.Photoname,
        carphoto: input.photo === undefined ? "" : input.photo,
        gear: parseInt(input.Charges),
        Cateloge: input.Cateloge,
        nInsurancetype: check === "Yes" ? input.nInsurancetype : "",
        nSunRoof: input.sunroof,
        vehicleid:input.vehicle,
        nCc: input.cc,
        nSaleprice: input.nSaleprice,
        nPurchaseprice: input.nPurchaseprice,
        nCaravaibility: carstatus,
        nFeatures: SelectedFeatures.map((item) => item.value).join(","),
      };
      // console.log(param);
      var formBody = [];
      for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
        body: formBody,
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/Carmaster/Editcar",
        requestOptions
      )
        .then((response) => response.json())
        .then(async (res) => {
          Toast(res.Success, "Vehicle successfully updated", res.Message);
          if (res.Success === true) {
            navigate("/MyCar");
          }
        });
    } 
  };
  const handleCallback = (childData) => {
    input["photo"] = childData.url.split(",")[1];
    input["Photoname"] = childData.name;
    setInput(input);
    setSrc(childData.url);
  };
  const selecteddate = (date) => {
    const parsedDate = parse(date, "dd-MM-yyyy", new Date());
    return parsedDate;
  };
  function getModalDropdown(id) {
    fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/activemodel/" + id)
      .then((response) => response.json())
      .then((res) => {
        setBrandlist(res.Data);
      });
  }
  function getVarientColorDropdown(compid, modelid) {
    var details =
      "companyid=" +
      encodeURIComponent(compid) +
      "&Modelid=" +
      encodeURIComponent(modelid);
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: details,
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/api/Common/veriantandcolour",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setVarientlist(res.Data.veriantdata);
        setColorlist(res.Data.Colourdata);
      });
  }
  const onstatuscheck = (e) => {
    const target = e.target;
    setCarstatus(target.checked ? "Yes" : "No");
  };

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="content">
        <div className="text-center">
          <h2 className="page-title">Edit Car</h2>
        </div>

        <div className="form-div">
          <label className="form-label">
            Vehicle Type
          </label>
          <select
            className="form-select form-control"
            name="vehicle"
            value={input.vehicle}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select vehicle Type--
            </option>
            {vehicletypes.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.vehicle}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Company</label>
          <select
            className="form-select form-control"
            name="company"
            value={input.company}
            onChange={handleCompanychange}
          >
            <option value="" disable="disable">
              --Select Company--
            </option>
            {Companylist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>
          <span className="text-danger">{errors.company}</span>
        </div>
       
        <div className="form-div">
          <label className="form-label">Model</label>
          <select
            className="form-select form-control"
            name="Model"
            value={input.Model}
            onChange={handleModalchange}
          >
            <option value="" disable="disable">
              --Select Model--
            </option>
            {Brandlist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.Model}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Varient</label>
          <select
            className="form-select form-control"
            name="varient"
            value={input.varient}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Varient--
            </option>
            {Varientlist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.varient}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Color</label>
          <select
            className="form-select form-control"
            name="color"
            value={input.color}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Color--
            </option>
            {Colorlist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.color}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Fuel</label>
          <select
            className="form-select form-control"
            name="fuel"
            value={input.fuel}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Fuel--
            </option>
            {Fuelist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.fuel}</span>
        </div>
        {/* <div className="form-div">
          <label className="form-label">Car Number</label>
          <input
            type="text"
            className="form-control"
            name="carnumber"
            value={input.carnumber}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.carnumber}</span>
        </div> */}
        <div className="form-div">
          <label className="form-label">Year</label>
          <input
            type="text"
            className="form-control"
            name="year"
            value={input.year}
            maxLength="4"
            onChange={HandleNumerictextbox}
          />
          <span className="text-danger">{errors.year}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Kilo Meter</label>
          <input
            type="text"
            className="form-control"
            name="km"
            value={input.km}
            maxLength={6}
            onChange={HandleNumerictextbox}
          />
          <span className="text-danger">{errors.km}</span>
        </div>

        <div className="form-div">
          <label className="form-label">Vehicle owner</label>
          <select
            className="form-select form-control"
            name="owner"
            value={input.owner}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Owner--
            </option>
            {ownerDrop.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.owner}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Seating capacity</label>
          <input
            type="text"
            className="form-control"
            name="seatingcapicity"
            value={input.seatingcapicity}
            maxLength={1}
            onChange={HandleNumerictextbox}
          />
          <span className="text-danger">{errors.seatingcapicity}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Gear</label>
          <select
            className="form-select form-control"
            name="Charges"
            value={input.Charges}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select Gear--
            </option>
            {Gearlist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>
          <span className="text-danger">{errors.Charges}</span>
        </div>

        <div className="form-div">
          <label className="form-label">Sunroof</label>
          <select
            className="form-select form-control"
            name="sunroof"
            value={input.sunroof}
            onChange={handleChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="form-div">
          <label className="form-label">Choose a vehicle feature</label>
          <Select
            isMulti
            name="feature"
            options={Features}
            onChange={ondropdown}
            value={SelectedFeatures}
            closeMenuOnSelect={false}
            isSearchable={false}
            blurInputOnSelect={false}
            styles={customStyles}
          ></Select>
        </div>

        <div className="form-div">
          <label className="form-label">Cubic capacity</label>
          <input
            type="number"
            step={0.01}
            className="form-control"
            name="cc"
            value={input.cc}
            maxLength="4"
            onChange={handleChange}
          />
          <span className="text-danger">{errors.cc}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Purchase price <Span>(optional)</Span>
          </label>
          <input
            type="text"
            className="form-control"
            name="nPurchaseprice"
            value={input.nPurchaseprice}
            maxLength={9}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.nPurchaseprice}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Sale price <Span>(optional)</Span>
          </label>
          <input
            type="text"
            className="form-control"
            name="nSaleprice"
            value={input.nSaleprice}
            maxLength={9}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.nSaleprice}</span>
        </div>
        <div className="form-check-inline form-div">
          <input
            type="checkbox"
            className="form-check-input"
            id="check2"
            name="carstatus"
            onChange={onstatuscheck}
            checked={carstatus === "Yes" ? true : false}
          />
          <label className="form-label ms-1" htmlFor="check2">
            Coming soon
          </label>
        </div>
        <div>
          <div className="form-check-inline form-div">
            <input
              type="checkbox"
              className="form-check-input"
              id="check1"
              name="insurance"
              onChange={oncheck}
              checked={check === "Yes" ? true : false}
            />
            <label className="form-label ms-1" htmlFor="check1">
              Insurance
            </label>
          </div>
          {check === "Yes" ? (
            <div>
              <div className="text-center">
                <div className="form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio1"
                    name="nInsurancetype"
                    value="Comprehensive"
                    checked={
                      input.nInsurancetype === "Comprehensive" ? true : false
                    }
                    onChange={handleChange}
                  />
                  <label className="form-check-label ps-1" htmlFor="radio1">
                    Comprehensive
                  </label>
                </div>
                <div className="form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    name="nInsurancetype"
                    value="Third Party"
                    onChange={handleChange}
                    checked={
                      input.nInsurancetype === "Third Party" ? true : false
                    }
                  />
                  <label className="form-check-label ps-1" htmlFor="radio2">
                    Third Party
                  </label>
                </div>
                <div className="form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio3"
                    name="nInsurancetype"
                    value="Zero depth"
                    checked={
                      input.nInsurancetype === "Zero depth" ? true : false
                    }
                    onChange={handleChange}
                  />
                  <label className="form-check-label ps-1" htmlFor="radio3">
                    Zero depth
                  </label>
                </div>
              </div>
              <div className="form-div">
                <label className="form-label">Insurance End Date</label>
                <DatePicker
                  defaultValue={input.enddate}
                  valueFormat={{ dateStyle: "medium" }}
                  name="enddate"
                  onChange={(e) => onDatechange(e, "enddate")}
                />
                <span className="text-danger">{errors.enddate}</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-div">
          <Upload_photo parentCallback={handleCallback} />

          <div className="col-12 text-center pt-2">
            <img src={src} style={{ maxWidth: "200px" }} alt="" />
          </div>
        </div>

        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Editinquiry;
