import React from "react";

function NoCars(props) {
  return (
    <div className="px-2 py-4 text-center">
      <img src="assets/images/no-car.png" alt="" className="nocar-img" />
      <h3 className="nocar-text">Sorry</h3>
      <p>No vehicle available!</p>
    </div>
  );
}

export default NoCars;
