import React, { useState, useEffect } from "react";
import { Phonepe, Toast } from "../Utils/general-function";
import { useNavigate, Link } from "react-router-dom";
import Loader from "../Utils/Loader";
import { Modal } from "react-bootstrap";

const statelist = [
  { Text: "Andaman and Nicobar Islands", Value: "Andaman and Nicobar Islands" },
  { Text: "Andhra Pradesh", Value: "Andhra Pradesh" },
  { Text: "Arunachal Pradesh", Value: "Arunachal Pradesh" },
  { Text: "Assam", Value: "Assam" },
  { Text: "Bihar", Value: "Bihar" },
  { Text: "Chandigarh", Value: "Chandigarh" },
  { Text: "Chhattisgarh", Value: "Chhattisgarh" },
  { Text: "Dadra and Nagar Haveli", Value: "Dadra and Nagar Haveli" },

  { Text: "Daman and Diu", Value: "Daman and Diu" },
  { Text: "Delhi", Value: "Delhi" },

  {
    Text: "National Capital Territory of Delhi",
    Value: "National Capital Territory of Delhi",
  },
  { Text: "Goa", Value: "Goa" },
  { Text: "Gujarat", Value: "Gujarat" },
  { Text: "Haryana", Value: "Haryana" },
  { Text: "Himachal Pradesh", Value: "Himachal Pradesh" },
  { Text: "Jammu and Kashmir", Value: "Jammu and Kashmir" },
  { Text: "Jharkhand", Value: "Jharkhand" },
  { Text: "Karnataka", Value: "Karnataka" },
  { Text: "Kerala", Value: "Kerala" },

  { Text: "Lakshadweep", Value: "Lakshadweep" },
  { Text: "Madhya Pradesh", Value: "Madhya Pradesh" },
  { Text: "Maharashtra", Value: "Maharashtra" },
  { Text: "Manipur", Value: "Manipur" },
  { Text: "Meghalaya", Value: "Meghalaya" },
  { Text: "Mizoram", Value: "Mizoram" },
  { Text: "Nagaland", Value: "Nagaland" },
  { Text: "Odisha", Value: "Odisha" },
  { Text: "Puducherry", Value: "Puducherry" },
  { Text: "Punjab", Value: "Punjab" },
  { Text: "Rajasthan", Value: "Rajasthan" },

  { Text: "Sikkim", Value: "Sikkim" },
  { Text: "Tamil Nadu", Value: "Tamil Nadu" },
  { Text: "Telangana", Value: "Telangana" },
  { Text: "Tripura", Value: "Tripura" },
  { Text: "Uttar Pradesh", Value: "Uttar Pradesh" },
  { Text: "Uttarakhand", Value: "Uttarakhand" },
  { Text: "West Bengal", Value: "West Bengal" },
];
function Register(props) {
  const [input, setInput] = useState({ terms: false });
  const [errors, setErrors] = useState({});
  const [Plans, setPlans] = useState([]);
  const [Billing, setBilling] = useState("none");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [Reqid, setReqid] = useState(false);
  const navigate = useNavigate();
  const validate = () => {
    let errors = {};
    let isValid = true;
    var regex1 = new RegExp(
      "^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$)"
    );
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Name is required.";
    }
    if (!input["mobileno"]) {
      isValid = false;
      errors["mobileno"] = "Mobile No is required.";
    }
    if (typeof input["mobileno"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(input["mobileno"])) {
        isValid = false;
        errors["mobileno"] = "Please enter only number.";
      } else if (input["mobileno"].length !== 10) {
        isValid = false;
        errors["mobileno"] = "Enter Valid Mobile number.";
      }
    }
    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Password is required.";
    }
    if (typeof input["password"] !== undefined) {
      const passwordRegex = new RegExp(
        /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/
      );
      if (!passwordRegex.test(input["password"])) {
        isValid = false;
        errors["password"] =
          "Password must be at least 6 characters long and contain at least one special character.";
      }
    }
    if (!input["plan"]) {
      isValid = false;
      errors["plan"] = "Please Select the plan";
    }
    if (!input["terms"]) {
      isValid = false;
      errors["terms"] = "Please accept Terms & Conditions.";
    }
    if (Billing === "block") {
      if (!input["gstno"]) {
        input["gstno"] = "";
        isValid = true;
      }
      if (typeof input["gstno"] !== "undefined") {
        if (input["gstno"] === "") {
          isValid = true;
        } else if (!regex1.test(input["gstno"])) {
          isValid = false;
          errors["gstno"] = "Invalid GST number";
        }
      }
      if (!input["pincode"]) {
        isValid = false;
        errors["pincode"] = "Pincode is required ";
      }
      if (typeof input["pincode"] !== "undefined") {
        if (input["pincode"].length !== 6) {
          isValid = false;
          errors["pincode"] = "Invalid Pincode.";
        } else if (!pattern.test(input["pincode"])) {
          isValid = false;
          errors["pincode"] = "Please enter only number.";
        }
      }
      if (!input["city"]) {
        isValid = false;
        errors["city"] = "City is required";
      }
      if (!input["state"]) {
        isValid = false;
        errors["state"] = "State is required";
      }
      if (!input["address"]) {
        isValid = false;
        errors["address"] = "Address is required";
      }
    }
    setErrors(errors);

    return isValid;
  };
  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      var param = {
        Name: input.name,
        Mobileno: input.mobileno,
        Password: input.password,
        address: input.address === undefined ? " " : input.address,
        state: input.state === undefined ? "" : input.state,
        City: input.city === undefined ? "" : input.city,
        Pincode: input.pincode === undefined ? "" : input.pincode,
        Gstno: input.gstno === undefined ? "" : input.gstno,
        Mobileno1: "",
        Website: "",
        Emailid: "",
        planid: input.plan,
        bTermandcondition: input.terms,
      };
      var formBody = [];
      for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/DealerRegistration/Registration",
        requestOptions
      )
        .then((response) => response.json())
        .then(async (res) => {
          setLoading(false);
          if (res.Success === true) {
            if (Billing === "block") {
              fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Test/getUrl")
                .then((response) => response.json())
                .then((res) => {
                  window.location.href = res.Data;
                });
              //Phonepe(res.Totalamount, res.Orderid);
            } else {
              Toast(res.Success, res.Message, res.Message);
              navigate("/Login");
            }
          } else {
            Toast(res.Success, "", res.Message);
          }
        });
    } 
  };

  const register = () => {
    var param = {
      Name: input.name,
      Mobileno: input.mobileno,
      Password: input.password,
      address: input.address === undefined ? " " : input.address,
      state: input.state === undefined ? "" : input.state,
      City: input.city === undefined ? "" : input.city,
      Pincode: input.pincode === undefined ? "" : input.pincode,
      Gstno: input.gstno === undefined ? "" : input.gstno,
      Mobileno1: "",
      Website: "",
      Emailid: "",
      planid: input.plan,
      bTermandcondition: input.terms,
    };
    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/DealerRegistration/Registration",
      requestOptions
    )
      .then((response) => response.json())
      .then(async (res) => {
        setLoading(false);
        if (res.Success === true) {
          if (Billing === "block") {
            fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Test/getUrl")
              .then((response) => response.json())
              .then((res) => {
                window.location.href = res.Data;
              });
            //Phonepe(res.Totalamount, res.Orderid);
          } else {
            Toast(res.Success, res.Message, res.Message);
            navigate("/Login");
          }
        } else {
          Toast(res.Success, "", res.Message);
        }
      });
  };

  const handleSubmit1 = () => {
    if (validate()) {
      setLoading(true);
      sendotp();
    } else {
      console.log("out");
    }
  };

  const handlechange = (event) => {
    const { name, value, checked, type } = event.target;
    setInput((prevInput) => ({
      ...prevInput,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlechangeplan = (event) => {
    input[event.target.name] = event.target.value;
    const selectedOption = event.target.selectedOptions[0];
    const dataAmount = selectedOption.getAttribute("data-amount");
    setInput(input);
    if (parseInt(dataAmount) > 0) {
      setBilling("block");
    } else {
      setBilling("none");
    }
  };
  useEffect(() => {
    let mounted = true;
    const script = document.createElement("script");
    // script.src = "assets/js/phonepe.js";
    // script.async = true;

    // document.body.appendChild(script);
    fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Getplan")
      .then((response) => response.json())
      .then((res) => {
        if (mounted) {
          setPlans(res.Data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return () => {
      mounted = false;
    };
  }, []);

  // useEffect(() => {
  //   // Define the configuration object
  //   const configuration = {
  //     widgetId: "3465756b5367353139383331" ,
  //     tokenAuth: "421315TZjMJeLo1nx664c898eP1",
  //     exposeMethods: true,
  //     captchaRenderId: "",
  //     // or true if you want to expose methods
  //     success: (data) => {
  //       // get verified token in response
  //       console.log("success response", data);
  //     },
  //     failure: (error) => {
  //       // handle error
  //       console.log("failure reason", error);
  //     },
  //     body_1: "<body_1>",
  //     body_2: "<body_2>",
  //     VAR1: "<VAR1>",
  //   };

  //   // Load the script dynamically
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src =
  //     "https://control.msg91.com/app/assets/otp-provider/otp-provider.js";
  //   script.onload = () => {
  //     // Initialize the widget after the script is loaded
  //     if (window.initSendOTP) {
  //       window.initSendOTP(configuration);
  //     }
  //   };
  //   document.body.appendChild(script);
  //   // Cleanup script from the document when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const sendotp = () => {
    window.sendOtp(
      input.mobileno, // mandatory
      (data) => {
        console.log("OTP sent successfully.", data);
        if (data.type === "success") {
          setReqid(data.message);
          setLoading(false);
          setShow(true);
        }
      },
      (error) => {
        console.log("Error occurred", error);
        setLoading(false);
        Toast(false, "", error.message);
      }
    );
  };

  const resend = (channel) => {
    setLoading(true);
    window.retryOtp(
      channel, // channel value mandatory
      (data) => {
        console.log("resend data: ", data);
        setLoading(false);
      }, // optional
      (error) => {
        console.log(error);
        setLoading(false);
        Toast(false, "", error.message);
      }, // optional
      Reqid // optional
    );
  };

  const verifyotp = () => {
    setLoading(true);
    window.verifyOtp(
      input.otp, // OTP value
      (data) => {
        console.log("OTP verified: ", data);
        if (data.type === "success") {
          Toast(true, "OTP verified successfully", "");
          setShow(false);
          register();
        }
      }, // optional
      (error) => {
        console.log(error);
        Toast(false, "", error.message);
        setLoading(false);
      },
      // Reqid  // optional
    );
  };
  function handlemodal() {
    setShow(!show);
  }

  return (
    <div style={{ marginTop: "-66px" }}>
      {loading ? <Loader /> : null}
      {/* <div id="otp-widget-container"></div> */}
      <div className="login">
        <div className="login-div">
          <div className="text-center pt-3 logo-div">
            <img src="assets/images/logo.png" className="logo-img" alt="" />
            <img
              src="assets/images/logo.png"
              className="logo-img logo-imgabs"
              alt=""
            />
          </div>
          <img src="assets/images/login-top.png" className="divider" alt="" />
          <div className="login-bg">
            <h2>Create Account</h2>
            <div className="inputwithicon">
              <input
                type="text"
                placeholder="Firm Name"
                name="name"
                onChange={handlechange}
              />
              <span className="material-symbols-outlined">account_circle</span>
              <label className="text-danger ms-3 ms-3">{errors.name}</label>
            </div>
            <div className="inputwithicon">
              <input
                type="text"
                placeholder="Mobile No."
                name="mobileno"
                onChange={handlechange}
              />
              <span className="material-symbols-outlined">phone_iphone</span>
              <label className="text-danger ms-3">{errors.mobileno}</label>
            </div>
            <div className="inputwithicon">
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={handlechange}
              />
              <span className="material-symbols-outlined">lock</span>
              <label className="text-danger ms-3">{errors.password}</label>
            </div>
            <div className="inputwithicon">
              <select name="plan" defaultValue="" onChange={handlechangeplan}>
                <option value="" disabled="disabled">
                  --Select Plan--
                </option>
                {Plans.map((list) => (
                  <option
                    data-amount={list.Amount}
                    value={list.Value}
                    key={list.Value}
                  >
                    {list.Text}
                  </option>
                ))}
              </select>
              <span className="material-symbols-outlined">
                format_list_bulleted
              </span>
              <label className="text-danger ms-3">{errors.plan}</label>
            </div>
            {Billing === "block" ? (
              <div>
                <div className="inputwithicon">
                  <textarea
                    placeholder="Address"
                    rows="4"
                    name="address"
                    onChange={handlechange}
                  ></textarea>
                  <span className="material-symbols-outlined">pin_drop</span>
                  <div className="text-danger ms-3">{errors.address}</div>
                </div>
                <div className="inputwithicon">
                  <select defaultValue="" name="state" onChange={handlechange}>
                    <option disabled="disabled" value="">
                      --Select State--
                    </option>
                    {statelist.map((list) => (
                      <option value={list.Value} key={list.value}>
                        {list.Text}
                      </option>
                    ))}

                    <option>Sikkim</option>
                    <option>Ladakh</option>
                  </select>
                  <span className="material-symbols-outlined">language</span>
                  <label className="text-danger ms-3">{errors.state}</label>
                </div>
                <div className="inputwithicon">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    onChange={handlechange}
                  />
                  <span className="material-symbols-outlined">apartment</span>
                  <label className="text-danger ms-3">{errors.city}</label>
                </div>
                <div className="inputwithicon">
                  <input
                    type="text"
                    placeholder="Pincode"
                    name="pincode"
                    onChange={handlechange}
                  />
                  <span className="material-symbols-outlined">pin</span>
                  <label className="text-danger ms-3">{errors.pincode}</label>
                </div>
                <div className="inputwithicon">
                  <input
                    type="text"
                    placeholder="Gst No."
                    name="gstno"
                    onChange={handlechange}
                  />
                  <span className="material-symbols-outlined">badge</span>
                  <label className="text-danger ms-3">{errors.gstno}</label>
                </div>
              </div>
            ) : null}
            <div className="form-check-inline ms-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="check2"
                name="terms"
                onChange={handlechange}
              />
              <a
                href="assets/images/Carthi User Terms.pdf"
                className="form-label ms-1"
                htmlFor="check2"
                style={{ color: "black" }}
              >
                I agree with{" "}
                <span style={{ textDecoration: "underline" }}>
                  Terms & Condition
                </span>
              </a>
            </div>{" "}
            <div className="text-danger ms-3">{errors.terms}</div>
            <div className="pt-2">
              <input
                type="submit"
                className="login-btn"
                value="Submit"
                onClick={(e) => handleSubmit()}
              />
            </div>
            <div className="new-account">
              Already have an account? <Link to="/Login">Login Now...</Link>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={(e) => handlemodal()}
        id="resultpopup"
        className="modal"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div class="text-center px-4 pb-3">
            <img
              src="assets/images/otp-image.png"
              style={{ maxWidth: "250px" }}
              alt=""
            />
            <h2 style={{ letterSpacing: "0.5px" }}>OTP Verification</h2>
            <p
              style={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              One-Time Password sent to your registered Whatsapp number. Please
              check your Whatsapp.
            </p>
            <input
              type="text"
              id="otp"
              name="otp"
              placeholder="Enter OTP"
              className="otp-input"
              onChange={handlechange}
            />
            <div className="resend-otp">
              Didn't receive OTP?
              <span className="resend-link" onClick={(e) => resend("12")}>
                {" "}
                Resend OTP
              </span>
            </div>

            <div className="text-center mt-2">
              <input
                type="submit"
                value="Verify"
                className="attachbtn w-100"
                style={{ borderRadius: "4px" }}
                onClick={(e) => {
                  verifyotp();
                  // handlemodal();
                }}
              />

              {/* end */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Register;
