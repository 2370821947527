import React, { useEffect, useState } from "react";
import Header from "../Header";
import Loader from "../Utils/Loader";

function AllFrame(props) {
  const [loading, setLoading] = useState(true);
  const [AllFrame, setAllFrame] = useState([]);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/api/FrameMaster/AllFrame",
      header
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        setLoading(false);
        setAllFrame(res.Data);
      });
  }, []);
  const onChange = (e) => {
    const body = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/api/FrameMaster/AddActiveFrame?frameid="+e.target.value,
      body
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log(res);
      });
    setSelected(e.target.value);
  };

  return (
    <>
      <Header />
      {loading ? <Loader /> : null}
      <div className="text-center">
          <h2 className="page-title">Branding Frame</h2>
        </div>
      <div className="page-space">
        {AllFrame.map((data) => (
          <div className="text-center position-relative" key={data.random}>
            <input
              type="radio"
              name="frame"
              id={data.random}
              className="frame-radio"
              value={data.id}
              onChange={onChange}
              defaultChecked={data.isSelectedframeid==="true"?true:false}
            />
            <div className="sel-frame">
              <img src={data.imageupload} className="img-fluid" alt="" />
              <label htmlFor={data.random} className="frame-btn">
                Select Frame
              </label>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default AllFrame;
