import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Upload_logo from "../Uploadimage/Upload-logo";
import { Toast, countryList } from "../Utils/general-function";
import Loader from "../Utils/Loader";
import { Modal } from "react-bootstrap";
function Profile(props) {
  const [input, setInput] = useState({
    src: "",
    name: "",
    website: "",
    email: "",
    Address: "",
    photo: "",
    Photoname: "",
    mobileno: "",
    Country: "",
    State: "",
    City: "",
    mobileno1: "",
    newcity: "",
    nGpslink: "",
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [States, setStates] = useState([]);
  const [Cities, setCities] = useState([]);
  const [isdisabled, setIsdisabled] = useState(false);
  const [iscitydisabled, setCityIsdisabled] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
    }));
  };

  const OnCountryChange = (e) => {
    const value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: value,
      State: "",
      City: "",
    }));
    fetchstate(value);
  };
  const handleSubmit = () => {
   
    if (Validate("All")) {
      setLoading(true);
      const param = {
        Logoname: input.Photoname ? input.Photoname : "",
        Firmname: input.name,
        Address: input.Address,
        Mobileno: input.mobileno,
        Email: input.email,
        Website: input.website,
        Logo: input.photo ? input.photo : "",
        Status: "Edit",
        Mobileno1: input.mobileno1,
        nCountry: input.Country,
        nState: input.State,
        nCity: input.City,
        nGpslink: input.nGpslink,
      };
      const formBody = new URLSearchParams(param).toString();

      const header = {
        mode: "cors",
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      };
      try {
        fetch(
          process.env.REACT_APP_API_ENDPOINT +
            "/api/BrandingProfile/AddBrandingProfile",
          header
        )
          .then((response) => response.json())
          .then((res) => {
            setLoading(false);
            if (res.Success) {
              localStorage.setItem("Ca1nioamed", input.name);
              navigate("/");
            }
            Toast(res.Success, "Profile Successfully updated", res.Message);
          });
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  const Validate = (what) => {
    let errors = {};
    let isValid = true;
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (what === "All") {
      if (!input["name"]) {
        isValid = false;
        errors["name"] = "Firmname is required.";
      }
      if (typeof input["name"] !== "undefined") {
        var re = new RegExp(/^[a-zA-Z\s']+$/);
        if (!re.test(input["name"])) {
          isValid = false;
          errors["name"] = "Please enter only characters.";
        }
      }
      if (!input["mobileno"]) {
        isValid = false;
        errors["mobileno"] = "Mobile is required.";
      }
      if (typeof input["mobileno"] !== "undefined") {
        if (!pattern.test(input["mobileno"])) {
          isValid = false;
          errors["mobileno"] = "Please enter only number.";
        } else if (input["mobileno"].length !== 10) {
          isValid = false;
          errors["mobileno"] = "Enter valid mobile number.";
        }
      }
      if (
        typeof input["mobileno1"] !== "undefined" &&
        input["mobileno1"] !== ""
      ) {
        let expression = new RegExp(/^\d{10}(?:\/\d{10}){0,2}$/);

        if (!expression.test(input["mobileno1"])) {
          isValid = false;
          errors["mobileno1"] = "Please enter only number.";
        }
        // else if (input["mobileno1"].length !== 10) {
        //   isValid = false;
        //   errors["mobileno1"] = "Enter valid mobile number.";
        // }
      }
      if (!input["email"]) {
        isValid = false;
        errors["email"] = "Emailid is required";
      }
      if (typeof input["email"] !== "undefined") {
        var regx =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\\.,;:\s@\"]+\.)+[^<>()[\]\\.,;:\s@\"]{2,})$/i;

        if (!regx.test(input["email"])) {
          isValid = false;
          errors["email"] = "Enter valid email address.";
        }
      }
      if (!input["Address"]) {
        isValid = false;
        errors["Address"] = "Address is required";
      }
      if (!iscitydisabled) {
        if (!input["City"]) {
          isValid = false;
          errors["City"] = "This field is required";
        }
      }
    }
    if (!input["Country"]) {
      isValid = false;
      errors["Country"] = "This field is required";
    }
    if (!isdisabled) {
      if (!input["State"]) {
        isValid = false;
        errors["State"] = "This field is required";
      }
    }
    if (what === "newcity") {
      if (!input["newcity"]) {
        isValid = false;
        errors["newcity"] = "This field is required";
      }
      if (typeof input["newcity"] !== "undefined") {
        var reg = new RegExp(/^[a-zA-Z\s']{1,20}$/);
        if (!reg.test(input["newcity"])) {
          isValid = false;
          errors["newcity"] = "Please enter valid city.";
        }
      }
    }

    setErrors(errors);

    return isValid;
  };
  useEffect(() => {
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    try {
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/countrydata")
        .then((response) => response.json())
        .then((res) => {
          setCountries(res.Data);

          fetch(
            process.env.REACT_APP_API_ENDPOINT + "/api/BrandingProfile/All",
            header
          )
            .then((response) => response.json())
            .then((res) => {
              let input = {};
              if (res.Data.length !== 0) {
                input.name = res.Data[0].Firmname;
                input.Address = res.Data[0].Address;
                input.mobileno = res.Data[0].Mobileno;
                input.email = res.Data[0].Email;
                input.Photoname = res.Data[0].Logoname;
                input.website = res.Data[0].Website;
                input.Photo = "";
                input.src = res.Data[0].Logo;
                input.Country = res.Data[0].nCountry;
                input.State = res.Data[0].nState;
                input.City = res.Data[0].nCity;
                input.mobileno1 = res.Data[0].Mobileno1;
                input.nGpslink = res.Data[0].nGpslink;
                setInput(input);
                if (res.Data[0].nCountry) {
                  fetchstate(res.Data[0].nCountry);
                  fetchcitiesbystate(res.Data[0].nCountry, res.Data[0].nState);
                }
              }
              setLoading(false);
            });
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const handleCallback = (childData) => {
    setInput((prevInput) => ({
      ...prevInput,
      photo: childData.url.split(",")[1],
      Photoname: childData.name,
      src: childData.url,
    }));
  };
  function handlemodal() {
    setShow(!show);
  }

  const handlestatechange = (e) => {
    setLoading(true);
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
      City: "",
    }));
    fetchcitiesbystate(input.Country, e.target.value);
  };

  const fetchcitiesbystate = (country, state) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Common/citynamedata?countryid=" +
        country +
        "&stateid=" +
        state
    )
      .then((response) => response.json())
      .then((res) => {
        setCities(res.Data || []);
        setLoading(false);
      });
  };

  const fetchstate = (country) => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Common/statenamedata?countryid=" +
        country
    )
      .then((response) => response.json())
      .then((res) => {
        setStates(res.Data || []);
        setLoading(false);
      });
  };

  const SubmitCity = async () => {
    if (Validate("newcity")) {
      const param = {
        cityname: input.newcity,
        countryid: input.Country,
        stateid: input.State,
      };
      const body = new URLSearchParams(param).toString();
      const header = {
        mode: "cors",
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: body,
      };
      try {
        const response = await (
          await fetch(
            process.env.REACT_APP_API_ENDPOINT + "/api/CityMaster/AddCity",
            header
          )
        ).json();
        if (response.Success) {
          setCities([...response.Data, ...Cities]);
          setInput((prevInput)=>({
            ...prevInput,
            City:response.Data[0].Value
          }))
          setShow(false);
        }else{
          setErrors((prevError)=>({
            ...prevError,
            "newcity":response.Message
          }))
        }
      } catch (error) {
        console.log("Error", error);
      }
    }
  };
  return (
    <div>
      <Header updated={input.City} />
      {loading ? <Loader /> : null}
      <div className="content">
        <div className="text-center">
          <h2 className="page-title">Profile</h2>
        </div>
        <div className="form-div">
          <label className="form-label">Firm Name</label>
          <input
            type="text"
            name="name"
            className="form-control"
            onChange={onChange}
            placeholder="Firm Name"
            value={input.name}
          />
          <span className="text-danger">{errors.name}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Select Country</label>
          <select
            className="form-select form-control"
            name="Country"
            value={input.Country}
            onChange={OnCountryChange}
          >
            <option value="" disabled={true}>
              -- Select Country --
            </option>
            {Countries.map((list, i) => (
              <option value={list.Value} key={i}>
                {list.Text}
              </option>
            ))}
          </select>
          <span className="text-danger">{errors.Country}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Select State</label>
          <select
            className="form-select form-control"
            name="State"
            value={input.State}
            onChange={handlestatechange}
            disabled={isdisabled}
          >
            <option value="" disabled={true}>
              -- Select State --
            </option>

            {States.map((list) => (
              <option value={list.Value} key={list.Text}>
                {list.Text}
              </option>
            ))}
          </select>
          <span className="text-danger">{errors.State}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Select City</label>
          <div className="d-flex flex-nowrap">
            <select
              className="form-select form-control"
              name="City"
              onChange={onChange}
              value={input.City}
              disabled={iscitydisabled}
            >
              <option value="" disabled="disabled">
                -- Select City --
              </option>
              {Cities.map((list) => (
                <option value={list.Value} key={list.Text}>
                  {list.Text}
                </option>
              ))}
            </select>
            <button
              className="add-citybtn"
              onClick={() => {
                if (Validate("Some")) {
                  handlemodal();
                }
              }}
            >
              <span className="material-symbols-outlined">add_circle</span>
            </button>
          </div>
          <span className="text-danger">{errors.City}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Address</label>
          <textarea
            name="Address"
            className="form-control"
            onChange={onChange}
            placeholder="Address"
            value={input.Address}
          />
          <span className="text-danger">{errors.Address}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Google Map Link</label>
          <input
            type="text"
            name="nGpslink"
            className="form-control"
            onChange={onChange}
            placeholder="Google Map Link"
            value={input.nGpslink}
          />
          <span className="text-danger"></span>
        </div>
        <div className="form-div">
          <label className="form-label">Mobile No.</label>
          <input
            type="text"
            name="mobileno"
            className="form-control"
            onChange={onChange}
            placeholder="Mobile No"
            value={input.mobileno}
          />
          <span className="text-danger">{errors.mobileno}</span>
        </div>
        <div className="form-div">
          <label className="form-label">
            Other No.
            <span
              style={{
                fontSize: "13px",
                color: "#686868",
                letterSpacing: "0.5px",
              }}
            >
              (Ex. 9999999999/1234567890)
            </span>
          </label>
          <input
            type="text"
            name="mobileno1"
            className="form-control"
            onChange={onChange}
            placeholder="Mobile No"
            value={input.mobileno1}
          />
          <span className="text-danger">{errors.mobileno1}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Email</label>
          <input
            type="text"
            name="email"
            className="form-control"
            onChange={onChange}
            placeholder="Email"
            value={input.email}
          />
          <span className="text-danger">{errors.email}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Website</label>
          <input
            type="text"
            name="website"
            className="form-control"
            onChange={onChange}
            placeholder="website"
            value={input.website}
          />
          <span className="text-danger"></span>
        </div>
        <div className="form-div">
          <Upload_logo parentCallback={handleCallback} />
          <div className="col-12 text-center pt-2">
            <img src={input.src} style={{ maxWidth: "200px" }} alt="" />
          </div>
        </div>
        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      <Modal
        show={show}
        onHide={(e) => handlemodal()}
        id="resultpopup"
        centered
      >
        <Modal.Header closeButton>
          <h2>Add a City</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <div className="form-div">
                <label className="form-label">City</label>
                <input
                  type="text"
                  name="newcity"
                  className="form-control"
                  onChange={onChange}
                  placeholder="City"
                  value={input.newcity}
                />
                <span className="text-danger">{errors.newcity}</span>
              </div>
              <div className="text-center mt-4">
                <input
                  type="submit"
                  value="Submit"
                  className="attachbtn"
                  onClick={(e) => {
                    SubmitCity();
                  }}
                />
              </div>
              {/* end */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Profile;
