import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Utils/Loader";
import { Userlogin, saveurl } from "./Utils/general-function";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Document from "./Document";
import NoCars from "./Utils/NoCars";

function SearchDoc(props) {
  const [loading, setLoading] = useState(true);
  const [Carlist, setCarlist] = useState([]);
  const [CarDOclist, setCarDOclist] = useState([]);
  const [searchtext, setSearchText] = useState("");
  const [originalList, setOriginalList] = useState([]);
  const [selectedcar, setSelectedcar] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (Userlogin()) {
      const header = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
      };

      fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/Carmaster/Dealercar",
        header
      )
        .then((response) => response.json())
        .then((res) => {
          setCarlist(res.Data || []);
          setOriginalList(res.Data || []);
          setLoading(false);
        });
    } else {
      navigate("/Login");
    }
  }, [navigate]);

  const Onchange = async (e) => {
    await setSearchText(e.target.value);
    let filterdlist = originalList.filter((d) =>
      d.carnumber.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setCarlist(filterdlist);
    // if (e.target.value !== "" && filterdlist.length > 0) {
    //    console.log(filterdlist,"in");
    //   document.getElementById("searchlist").style.display = "block";
    // }

    setSelectedcar("");
  };

  const Test = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the user is on an Android device
    const isAndroid = /android/i.test(userAgent);

    if (isAndroid) {
      // Open Google app using intent
      window.location.href =
        "intent://www.google.com/#Intent;package=com.google.android.googlequicksearchbox;scheme=https;end";
    } else {
      // Fallback to opening Google in the browser
      window.location.href = "https://www.google.com";
    }
  };

  const GetDocs = (id, no) => {
    setLoading(true);
    document.getElementById("searchlist").style.display = "none";
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/cardocumentmaster/Allcardocument?carid=" +
        id,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        setCarDOclist(res.Data);
        setSelectedcar(no);
        // document.getElementById("searchlist").style.display="block"
        setLoading(false);
      });
  };
  const formatResult = (Carlist) => {
    return (
      <>
        <ul className="doc-searchresult" id="searchlist">
          {Carlist.map((list) => (
            <li
              key={list.carnumber}
              style={{ display: "block", textAlign: "left" }}
              onClick={(e) => GetDocs(list.carid, list.carnumber)}
            >
              {list.carnumber} -{list.companyid}&nbsp;
              {list.carmodelid}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div>
      {loading ? <Loader /> : null}
      <Header />
      <div className="page-space">
        <div className="car-searchbar">
          <button>
            <img src="assets/images/search-icon.png" alt="" />
          </button>
          <input
            type="text"
            className="doc-serachtext"
            placeholder="Enter vehicle no..."
            onChange={Onchange}
          />
        </div>

        {searchtext && Carlist.length > 0 ? formatResult(Carlist) :null}
        {Carlist.length=== 0?<NoCars/>:"" }
        {searchtext === "" || selectedcar === "" ? null : (
          <Document dataFromParent={{ no: selectedcar, list: CarDOclist }} />
        )}
      </div>
    </div>
  );
}

export default SearchDoc;
