// for sharing Detail page of catlogue car

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Shareurl } from "../../Utils/general-function";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loader from "../../Utils/Loader";
import Info from "../../Utils/Info";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Titleinfo from "../../Utils/Titleinfo";
import Footer from "../../Utils/Footer";
import ScrollButton from "../../Utils/scrollbutton"
function CatDetails(props) {
  const [Catloguedetail, setCatloguedetail] = useState({ carnumber: "" });
  const [Photolist, setPhotolist] = useState([]);
  const [DealerData, setDealerData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  let params = useParams();
  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/CarCateloge/catelogecardetailsbyrandomno?Randno=" +
        params.carno+"&dealerid="+params.dealerid
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          setCatloguedetail(res.Data);
          setPhotolist(res.Data.catlogephotolist);
        }
      });

      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/BrandingProfile/GetBrandingProfile?Rand="+params.dealerid)
        .then((response) => response.json())
        .then((res) => {
          if (res.Success === true) {
            setDealerData(res.Data);
          }
        });
  }, [navigate, params.carno, params.dealerid]);

  const handleload=()=>{
    setLoading(false)
  }
  const Poster = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    cssEase: "linear",
  };
  return (
    <div style={{ marginTop: "-66px" }} className="mb-2">
      {loading ? <Loader /> : null}
      <div>
        <div id="forshare">
          <Titleinfo
            dataFromParent={{
              data: Catloguedetail,
              carno:
                "****" +
                Catloguedetail.carnumber.substring(6),
            }}
          />
        </div>
        <div className="carslider npd2">
          <Slider {...Poster}>
            {Photolist.map((list) => (
              <div className="car-reldiv" key={list.id}>
                <img src="assets/images/abslogo.png" class="abs-logo" style={{zIndex: "1"}} alt="" />
                <TransformWrapper>
                  <TransformComponent>
                    <img
                      src={list.carphoto}
                      className="car-image"
                      alt=""
                      onLoad={handleload}
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <Info dataFromParent={Catloguedetail} />
      <div className={params.brandoption==='W_hB'?'mb-2':'d-none'}>
        <Footer dataFromParent={DealerData}/>

      </div>
      {/* <ScrollButton/> */}
    </div>
  );
}

export default CatDetails;
