import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Header from "./Header";
import {
  Shareurl,
  Userlogin,
  GetDealerdata,
  fetchDataFromApi,
  Toast,
} from "./Utils/general-function";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Loader from "./Utils/Loader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RWebShare } from "react-web-share";
import styled from "styled-components";
import Profilerequired from "./Account/profilerequired";
// import { UserProfile ,ApiProvider} from "./ApiContext";

// const Body = styled.body`
//   background: linear-gradient(180deg, rgb(255, 94, 94) 0%, rgb(255, 0, 0) 40%, rgb(0, 0, 0);
//   min-height: 100dvh;
// `;
const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border-radius: 4px;
  font-family: "Rubik", sans-serif;
  padding: 7px 21px;
  font-size: 1rem;
  color: white;
  user-select: none;
  border: none;
  background-color: ${(props) => props.bgColor || "red"};
`;

const Dashboard = React.memo((props) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [Banner, setBanner] = useState([]);
  const [count, setCount] = useState({
    Totalcars: 0,
    TotalUnsoldcars: 0,
    TotalSoldcars: 0,
    TotalCatelogecar: 0,
    TotalCarinCateloge: 0,
  });
  // const { apiData,updateUserProfile } = UserProfile();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const loadScript = () => {
        return new Promise((resolve, reject) => {
          const existingScript = document.getElementById("gravitec-script");
          if (existingScript) {
            resolve(); // Script is already loaded
            return;
          }

          const script = document.createElement("script");
          script.src =
            "https://cdn.gravitec.net/storage/6504ce9788418d04e4fd16f071126219/client.js";
          script.async = true;
          script.id = "gravitec-script";
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
      };

      try {
        if (!Userlogin()) {
          navigate("/Login");
          return;
        }
        // For Notification
        await loadScript();

        const Dealerdata = await GetDealerdata();

       // permissiontoggeled(Dealerdata.nPostpermission);
        localStorage.setItem("Permission", Dealerdata.nPostpermission);

        if (Dealerdata.nCity === "") {
          console.log("in");

          setShow(true);
          // Toast(false, "", "Please Complete your Profile");
          // navigate("/Profile");
          // return;
        }

        // Fetching dashboard data
        const dashboardData = await fetchDataFromApi(
          "/api/Carmaster/Dashboardcounter"
        );
        if (
          dashboardData?.Message ===
          "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        const count = {
          TotalCatelogecar: dashboardData.Data.TotalCatelogecar,
          TotalSoldcars: dashboardData.Data.TotalSoldcars,
          TotalUnsoldcars: dashboardData.Data.TotalUnsoldcars,
          Totalcars: dashboardData.Data.Totalcars,
          TotalCarinCateloge: dashboardData.Data.TotalCarinCateloge,
        };
        setCount(count);

        // Fetch banner data
        const bannerData = await fetchDataFromApi(
          "/api/Bannermaster/AllBanner"
        );
        setBanner(bannerData.Data);

        setLoading(false);
      } catch (error) {
        console.error("An error occurred:", error);
        setLoading(false);
      }
    };

    fetchData();
  
    return () => {
      const script = document.getElementById("gravitec-script");
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [navigate]);

  const onShare = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let value = "";

        return (
          <div className="popup-overlay px-3 py-3">
            <div className="form-div">
              <label className="form-label">Mobile Number</label>
              <input
                type="text"
                className="form-control"
                name="mobileno"
                onChange={(e) => {
                  value = e.target.value;
                }}
              />
              {/* <span className="text-danger">{errors.name}</span> */}
            </div>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                if (value !== "") {
                  const text = encodeURIComponent(
                    "https://m.carthi.in/#/Catlogueshare/" +
                      localStorage.getItem("Ca1itd") +
                      "/" +
                      encodeURIComponent(
                        localStorage
                          .getItem("Ca1nioamed")
                          ?.replace(/[,& . ']/g, "")
                      )
                  );
                  const iphone = window.navigator.userAgent.includes("Mac");
                  if (iphone) {
                    window.location.href = `https://wa.me/+91${value}?text=${text}`;
                  } else {
                    window.location.href = `intent://send?phone=${value}&text=${text}#Intent;scheme=whatsapp;package=com.whatsapp.w4b;end`;
                  }
                  onClose();
                }
              }}
            >
              Send
            </button>
            {/* <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button> */}
          </div>
        );
      },
    });
  };

  const closemodel = () => {
    setShow(!show);
  };

  const permissiontoggeled = (status) => {
    const YesButton = document.getElementById("Allow");
    const NoButton = document.getElementById("Deny");
    if (status === "Allow") {
      YesButton.textContent = "Allowed";
      NoButton.textContent = "Deny";
    } else {
      // NoButton.textContent = "Denied";
      YesButton.textContent = "Allow";
    }
  };
  const permissionallowed = async (status) => {
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    const Response = await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/BrandingProfile/UpdatePostPermission?postpermission=" +
        status,
      header
    );
    const Permission = await Response.json();
    if (Permission.Success) {
      localStorage.setItem("Permission", status);
      permissiontoggeled(status);
    }
  };
  const Poster = {
    infinite: true,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    swipeToSlide: true,
    cssEase: "linear",
  };
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="slider-section">
        <div className="slider-images">
          <Slider {...Poster}>
            {Banner.map((data) => (
              <Link to={data.nUrl} key={data.nPhoto}>
                <img src={data.nPhoto} alt="" />
              </Link>
            ))}
          </Slider>
        </div>
      </div>

      <div className="restpart">
        <div className="dash-grid">
          <Link to="/MyCar">
            <div className="dash-block">
              <span>My vehicles</span>
              <div className="d-flex justify-content-between align-items-center totalcar">
                <h3>{count.Totalcars}</h3>
                <img src="assets/images/car12.png" alt="" />
              </div>
            </div>
          </Link>
          <Link to={"/Catlogue/" + localStorage.getItem("Ca1itd")}>
            <div className="dash-block">
              <span>My catalogue</span>
              <div className="d-flex justify-content-between align-items-center totalcar">
                <h3>{count.TotalCatelogecar}</h3>
                <img src="assets/images/catalogue12.png" alt="" />
              </div>
            </div>
          </Link>
          <Link to={"/Soldunsold"} state={{ status: "Sold" }}>
            <div className="dash-block">
              <span>Sold vehicles</span>
              <div className="d-flex justify-content-between align-items-center totalcar">
                <h3>{count.TotalSoldcars}</h3>
                <img src="assets/images/car-sold12.png" alt="" />
              </div>
            </div>
          </Link>
          <Link to={"/Soldunsold"} state={{ status: "Unsold" }}>
            <div className="dash-block">
              <span>Unsold vehicles</span>
              <div className="d-flex justify-content-between align-items-center totalcar">
                <h3>{count.TotalUnsoldcars}</h3>
                <img src="assets/images/car-unsold12.png" alt="" />
              </div>
            </div>
          </Link>
          <Link to={"/CreateStatus"}>
            <div className="dash-block">
              <span>Create status</span>
              <div className="d-flex justify-content-between align-items-center totalcar">
                <h3>{count.Totalcars}</h3>
                <img
                  src="assets/images/status12.png"
                  alt=""
                  style={{ padding: "1vw" }}
                />
              </div>
            </div>
          </Link>
          <Link to={"/Service"}>
            <div className="dash-block">
              <span>Services</span>
              <div className="d-flex justify-content-between align-items-center totalcar">
                <h3>7</h3>
                <img src="assets/images/service12.png" alt="" />
              </div>
            </div>
          </Link>
        </div>
        {/* <div className="cat-link">
          <div>
            <h3 className="mb-2">Autopost your car on Carthi facebook page </h3>
            <span>
              Would you like Carthi to post your car catalog on our{" "}
              <a
                href="https://www.facebook.com/carthiofficial"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  color: "red",
                  fontWeight: "500",
                }}
              >
                Carthi page
              </a>{" "}
              to reach a wider audience? Reply 'Allow' to proceed or 'Deny' to
              opt out.
            </span>
          </div>

          <div className="d-flex justify-content-center mt-2">
            <Button
              className="ms-2"
              bgColor="green"
              id="Allow"
              onClick={(e) => permissionallowed("Allow")}
            >
              Allow
            </Button>
            <Button
              className="ms-2"
              bgColor="#ed1c24"
              id="Deny"
              onClick={(e) => permissionallowed("Not Allow")}
            >
              Deny
            </Button>
          </div>
        </div> */}
        <div className="cat-link">
          <div>
            <h3>Your catalogue link :</h3>
            <span>
              https://m.carthi.in/#/Catlogueshare/
              {localStorage.getItem("Ca1itd")}/
              {localStorage.getItem("Ca1nioamed")?.replace(/[,& . ']/g, "")}
            </span>
          </div>

          <div className="d-flex justify-content-between mt-2">
            <RWebShare
              data={{
                text: "",
                url:
                  "https://m.carthi.in/#/Catlogueshare/" +
                  localStorage.getItem("Ca1itd") +
                  "/" +
                  localStorage.getItem("Ca1nioamed"),
                title: localStorage.getItem("Ca1nioamed"),
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className="sharebtn">
                Share catalogue
                <span>
                  <img src="assets/images/share.png" alt="" />
                </span>
              </button>
            </RWebShare>
            {/* <button
            className="sharebtn"
            onClick={(e) =>
              Shareurl(
                "My Portfolio",
                localStorage.getItem("Ca1nioamed")
              )
            }
          >
            Share catalogue
            <span>
              <img src="assets/images/share.png" alt="" />
            </span>
          </button> */}
            <button className="sharebtn ms-2" onClick={(e) => onShare()}>
              Share to Number
              <span>
                <img src="assets/images/share.png" alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>

      <Profilerequired open={show} closehandler={(e) => closemodel()} />
      {/* <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <iframe 
        src="https://m.anchoredge.in"
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="External Website"
      />
    </div> */}
    </div>
  );
});

export default Dashboard;
